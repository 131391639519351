import React, { useEffect } from 'react';
import { Field, SitecoreContext, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Tabs from '@material-ui/core/Tabs/Tabs';

import './index.scss';
import {
  buildSearchBox,
  buildResultList,
  buildPager,
  buildQuerySummary,
  buildTab,
  buildQueryExpression,
  buildDidYouMean,
  loadSearchAnalyticsActions,
  loadSearchActions,
  loadAdvancedSearchQueryActions,
} from '@coveo/headless';
import config from '../../../temp/config';
import { SearchBox } from '../lib/searchbox';
import { ResultList } from '../lib/resultlist';
import { CoveoTab } from '../lib/tab';
import { Pager } from '../lib/pager';
import { QuerySummary } from '../lib/querysummary';
import { DefaultResult } from '../lib/resulttemplates/defaultresult';
import { UrlManager } from '../lib/utils';
import { DidYouMean } from '../lib/didyoumean';
import HeadlessEngine from '../lib/engine';

type SearchPageProps = {
  fields: {
    Heading: Field<string>;
    PlaceholderText: Field<string>;
    Tab1: Field<string>;
    Tab2: Field<string>;
    Tab3: Field<string>;
    Tab4: Field<string>;
    NoResults: Field<any>;
    QuerySummaryText: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean; custom: any };
};

const headlessEngine = new HeadlessEngine(config.coveoQueryPipeline, 'CroatiaSearchHub').engine;

const SearchPage = ({ fields, sitecoreContext }: SearchPageProps): JSX.Element => {
  const searchBox = buildSearchBox(headlessEngine);
  const querySummary = buildQuerySummary(headlessEngine);
  const didYouMean = buildDidYouMean(headlessEngine);
  const resultList = buildResultList(headlessEngine, {
    options: {
      fieldsToInclude: [
        'metaz32xdescription',
        'title',
        'type',
        'z95xtemplatename',
        'z95xfullpath',
        'pagez32xbodyz32xtitle',
      ],
    },
  });
  const pager = buildPager(headlessEngine);

  const alltab = buildTab(headlessEngine, {
    initialState: { isActive: true },
    options: {
      id: 'All Results',
      expression: '',
    },
  });

  const servicestab = buildTab(headlessEngine, {
    options: {
      id: 'Services',
      expression: buildQueryExpression()
        .addStringField({
          field: 'z95xpath',
          operator: 'contains',
          values: ['b7484305c40F43daaC3b5c47b812e04c'],
        })
        .toQuerySyntax(),
    },
  });

  const conditionstab = buildTab(headlessEngine, {
    options: {
      id: 'Conditions',
      expression: buildQueryExpression()
        .addStringField({
          field: 'z95xpath',
          operator: 'contains',
          values: ['9d6d0d686d4d48268c8608c0d45630c1'],
        })
        .toQuerySyntax(),
    },
  });

  function getQueryExpression(sitecoreContext: any) {
    const database = sitecoreContext?.route?.databaseName;
    var currentIndex = 'Coveo_' + database + '_index';
    var siteName = sitecoreContext?.site?.name;
    var sysSource = currentIndex + ' - ' + config.coveoFarmName;
    var currentLanguage = sitecoreContext?.language;
    var languageQuery = '@language==' + currentLanguage;
    var versionQuery = ' @z95xlatestversion==1';
    var siteQuery = ' @site==' + siteName;
    var sourceQuery = ' @syssource=="' + sysSource;
    var hideFromSearch = ' @hidez32xfromz32xsearch==0';
    var hasLayout = ' @haslayout==1';
    var mediaQuery = '(@z95xfullpath *= "/project/upmc*"' + sourceQuery + '"' + versionQuery + ')';
    var nonMediaQuery =
      '(' +
      languageQuery +
      versionQuery +
      hasLayout +
      siteQuery +
      sourceQuery +
      '"' +
      hideFromSearch +
      ')';
    var queryExpression = '(' + nonMediaQuery + ' OR ' + mediaQuery + ')';
    return queryExpression;
  }

  useEffect(() => {
    const { logInterfaceLoad } = loadSearchAnalyticsActions(headlessEngine);
    const { executeSearch } = loadSearchActions(headlessEngine);

    const cqaction = loadAdvancedSearchQueryActions(headlessEngine).registerAdvancedSearchQueries({
      cq: getQueryExpression(sitecoreContext),
    });

    headlessEngine.dispatch(cqaction);
    headlessEngine.dispatch(executeSearch(logInterfaceLoad()));
    UrlManager(headlessEngine);
  });

  return (
    <div className="headless-search-page">
      {sitecoreContext.pageEditing && (
        <>
          <p>SearchPage Component Edit Mode</p>
        </>
      )}
      <div className="search-container">
        <div className="search-box-container">
          <div className="hero-search-overlay-container">
            <div className="search-border-spacer">
              <SearchBox controller={searchBox} placeholderText={fields?.PlaceholderText?.value} />
            </div>
          </div>
        </div>
        <div className="search-title">
          <QuerySummary controller={querySummary} title={fields?.QuerySummaryText?.value} />
          <DidYouMean controller={didYouMean} />
        </div>
        <div className="search-tab">
          <Tabs aria-label="simple tabs example" variant="scrollable" scrollButtons="on">
            <CoveoTab
              controller={alltab}
              title={fields?.Tab1?.value}
              value="0"
              pagerController={pager}
            ></CoveoTab>
            <CoveoTab
              controller={servicestab}
              title={fields?.Tab2?.value}
              value="1"
              pagerController={pager}
            ></CoveoTab>
            {/* <CoveoTab
              controller={conditionstab}
              title={fields?.Tab3?.value}
              value="2"
              pagerController={pager}
      ></CoveoTab> */}
          </Tabs>
        </div>
        <hr className="hr" />
        <div className="mainresults-area">
          <ResultList
            controller={resultList}
            hostName={sitecoreContext?.custom?.targetHostName}
            engine={headlessEngine}
            noResults={fields?.NoResults.value}
          >
            <DefaultResult engine={headlessEngine}></DefaultResult>
          </ResultList>
        </div>
        <hr className="hr" />
        <div className="footer-area">
          <Pager controller={pager} />
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(SearchPage);
