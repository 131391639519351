import React from 'react';
import {
  Placeholder,
  RouteData,
  ComponentRendering,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import { Grid, Container } from '@material-ui/core';
import './index.scss';

type RenderingProps = {
  rendering: RouteData | ComponentRendering;
  sitecoreContext: any;
};

const TwoColumnwithLeftNav = ({ rendering, sitecoreContext }: RenderingProps) => {
  const pageHeroComponent = rendering.placeholders?.page_content?.find(
    (component: ComponentRendering) => component.componentName === 'PageHero'
  );

  const otherComponents = rendering.placeholders?.page_content?.filter(
    (component: ComponentRendering) => component.componentName !== 'PageHero'
  );

  return (
    <>
      {sitecoreContext.pageEditing ? (
        <>
          {!!sitecoreContext.route.fields['Page Body Title']?.value && (
            <h1>{sitecoreContext.route.fields['Page Body Title']?.value}</h1>
          )}
          <Container maxWidth={false} disableGutters>
            <Grid container direction="row">
              <Grid item xs={12}>
                <Placeholder name="page_top_section" rendering={rendering} />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              xs={12}
            >
              <Grid container item xs={12} className="two-column-with-left-nav">
                <Grid item xs={12} lg={3}>
                  <Placeholder name="page_leftnav" rendering={rendering} />
                </Grid>
                <Grid item xs={12} lg={9} id="center-content">
                  <Placeholder name="page_content" rendering={rendering} />
                </Grid>
              </Grid>
            </Grid>
            <Grid container direction="row">
              <Grid item xs={12}>
                <Placeholder name="page_section" rendering={rendering} />
              </Grid>
            </Grid>
          </Container>
        </>
      ) : (
        <Container maxWidth={false} disableGutters>
          <Grid container direction="row">
            <Grid item xs={12}>
              <Placeholder name="page_top_section" rendering={rendering} />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            xs={12}
          >
            <Grid container item xs={12} className="two-column-with-left-nav">
              <Grid item xs={12} lg={3}>
                <Placeholder name="page_leftnav" rendering={rendering} />
              </Grid>
              <Grid item xs={12} lg={9} id="center-content">
                {pageHeroComponent && (
                  <Placeholder
                    name="page_content"
                    rendering={{
                      ...rendering,
                      placeholders: { page_content: [pageHeroComponent] },
                    }}
                  />
                )}
                {!!sitecoreContext.route.fields['Page Body Title']?.value && (
                  <h1>{sitecoreContext.route.fields['Page Body Title']?.value}</h1>
                )}
                {otherComponents && (
                  <Placeholder
                    name="page_content"
                    rendering={{ ...rendering, placeholders: { page_content: otherComponents } }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={12}>
              <Placeholder name="page_section" rendering={rendering} />
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};

export default withSitecoreContext()(TwoColumnwithLeftNav);
